import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { StatisticModel } from '../../../../models/statistis.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorResponse } from '../../../../models/error-response.model';
import { Chart } from 'chart.js';
import { ProfileService } from '../../../../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DomainModel } from '../../../../models/domain.model';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { AuthService } from '../../../../services/auth.service';
import { ApiMessage } from '../../../../models/api-message.model';
import { ConfirmModal } from '../../../../components/modal-confirm/modal-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { CDTrafficItemModel } from '../../../../models/traffic.model';

const _10m = 1000 * 60 * 10
const _1h = 1000 * 60 * 60
const _12h = _1h * 12
const _24h = _1h * 24
const _7d = _24h * 7
const _1m = _24h * 30

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.sass']
})
export class DeviceComponent implements OnInit {
  @ViewChild('chartSystem') chartSystemRef: ElementRef;
  @ViewChild('chartTraffic') chartTrafficRef: ElementRef;
  @ViewChild('modalKeenDNS')

  public modalKeenDNS: ModalTemplate<IContext, string, string>;
  keendnsName = '';
  keendnsCID = '';
  keendnsList: DomainModel[] = []
  keendnsSelected = ''
  errorKeendnsGetList = null
  isKeendnsLoaded = true

  @ViewChild('modalLogs')
  public modalLogs: ModalTemplate<IContext, string, string>;
  logslist = {};

  //----------------------------------------------------------//
  isLoading = false;

  chartSystem
  chartTraffic

  public curentFilterSystemBy: string
  public curentFilterTrafficBy: string

  currentCID = ''
  network = {};
  device = {};

  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private authService: AuthService,
    public modalService: SuiModalService,
    public translate: TranslateService
  ) {
    this.currentCID = this.route.snapshot.paramMap.get('cid');
    this.setCurrentFilterTraffic('10min')
    this.setCurrentFilterSystem('10m')
  }

  ngOnInit() {
    this.isLoading = true;

    Promise.all([
      this.profileService.getDevice(this.currentCID)
    ])
      .then((values: Object[]) => {
        this.responseIsError(values[0])
        let resp = values[0] as Object;
        this.device = resp['device']
        this.network = resp['network']
        this.isLoading = false;
      }).catch((re: HttpErrorResponse) => {
        this.router.navigate(['/networks']);
      });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  setCurrentFilterTraffic(s: string) {
    this.curentFilterTrafficBy = s

    let v = this.dataService.getStatisticV2(this.currentCID, this.curentFilterTrafficBy)
    if (v instanceof ErrorResponse) {
      const error = v as ErrorResponse;
    }

    let optionTraffic = {
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItems, data) {
            return data.datasets[tooltipItems.datasetIndex].label + ': ' + bytesToSize(tooltipItems.yLabel)
          }
        }
      },
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          ticks: {
            callback: function (value, index, values) {
              if (value < 1) {
                value = 0
              }
              return bytesToSize(value);
            }
          }
        }]
      }
    }

    v.then((v: CDTrafficItemModel[]) => {
      let ctx2 = this.chartTrafficRef.nativeElement

      if (this.chartTraffic) {
        this.chartTraffic.destroy()
      }

      var rxdata = [];
      var txdata = [];
      var labels = [];

      v.forEach((v, i, arr) => {
        rxdata.push(v.rx)
        txdata.push(v.tx)

        var date = new Date(v.ts)
        var label: string;

        switch (this.curentFilterTrafficBy) {
          case "10min":
          case "60min":
          case "24h":
            var hours = date.getHours();
            var minutes = "0" + date.getMinutes();
            label = hours + ':' + minutes.substr(-2);
            break
          case "7d":
          case "30d":
          case "12m":
            var day = "0" + date.getDate();
            var month = "0" + (date.getMonth() + 1);
            label = day.substr(-2) + '.' + month.substr(-2)
            break
        }
        labels.push(label)
      })

      this.chartTraffic = new Chart(ctx2, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'download',
              data: rxdata,
              backgroundColor: 'rgba(0,181,238,0.2)',
              borderColor: 'rgba(0,181,238,0.2)'
            },
            {
              label: 'upload',
              data: txdata,
              backgroundColor: 'rgba(151,198,107,0.2)',
              borderColor: 'rgba(151,198,107,0.2)'
            }
          ]
        },
        options: optionTraffic
      });
    }).catch((v) => {
      let ctx2 = document.getElementById("chartTraffic")
      this.chartTraffic = new Chart(ctx2, {
        type: 'bar',
        data: {
          labels: [],
          datasets: [
            {
              label: 'download',
              data: [],
              backgroundColor: 'rgba(0,181,238,0.5)',
              borderColor: 'rgba(0,181,238,0.5)'
            },
            {
              label: 'upload',
              data: [],
              backgroundColor: 'rgba(151,198,107,0.5)',
              borderColor: 'rgba(151,198,107,0.5)'
            }
          ]
        },
        options: optionTraffic
      });

    })

  }

  setCurrentFilterSystem(s: string) {
    let chartLabels = []
    this.curentFilterSystemBy = s
    let d = new Date()
    let start = d.getTime()

    d.setMilliseconds(0)
    d.setSeconds(0)

    switch (s) {
      case '10m':
      case '1h':
        d.setMinutes(d.getMinutes() + 1)
        break
      case '12h':
      case '24h':
        d.setMinutes(0)
        d.setHours(d.getHours() + 1)
        break
      case '7d':
        d.setMinutes(0)
        d.setHours(0)
        d.setDate(d.getDate() + 1)
        break
      case '1m':
        d.setMinutes(0)
        d.setHours(0)
        d.setDate(d.getDate() + 1)
        d.setMonth(d.getMonth())
        break
    }

    const stop = d.getTime()
    let points = 0

    switch (s) {
      case '10m':
        start = stop - _10m
        points = 10
        var arr = []
        for (var i = 0; i < points; i++) {
          let nextTS = new Date(start + i * (stop - start) / points)
          var hours = nextTS.getHours();
          var minutes = "0" + nextTS.getMinutes();
          chartLabels.push(hours + ':' + minutes.substr(-2))
        }

        break
      case '1h':
        start = stop - _1h
        points = 60
        var arr = []
        for (var i = 0; i < points; i++) {
          let nextTS = new Date(start + i * (stop - start) / points)
          var hours = nextTS.getHours();
          var minutes = "0" + nextTS.getMinutes();
          chartLabels.push(hours + ':' + minutes.substr(-2))
        }

        break
      case '12h':
        start = stop - _12h
        points = 12
        var arr = []
        for (var i = 0; i < points; i++) {
          let nextTS = new Date(start + i * (stop - start) / points)
          var hours = nextTS.getHours();
          var minutes = "0" + nextTS.getMinutes();
          chartLabels.push(hours + ':' + minutes.substr(-2))
        }

        break
      case '24h':
        start = stop - _24h
        points = 24
        var arr = []
        for (var i = 0; i < points; i++) {
          let nextTS = new Date(start + i * (stop - start) / points)
          var hours = nextTS.getHours();
          var minutes = "0" + nextTS.getMinutes();
          chartLabels.push(hours + ':' + minutes.substr(-2))
        }

        break
      case '7d':
        start = stop - _7d
        points = 7
        var arr = []
        for (var i = 0; i < points; i++) {
          let nextTS = new Date(start + i * (stop - start) / points)
          var day = "0" + nextTS.getDate();
          var month = "0" + (nextTS.getMonth() + 1);
          chartLabels.push(day.substr(-2) + '.' + month.substr(-2))
        }

        break
      case '1m':
        start = stop - _1m
        points = 30
        var arr = []
        for (var i = 0; i < points; i++) {
          let nextTS = new Date(start + i * (stop - start) / points)
          var day = "0" + nextTS.getDate();
          var month = "0" + (nextTS.getMonth() + 1);
          chartLabels.push(day.substr(-2) + '.' + month.substr(-2))
        }

        break
    }

    let v = this.dataService.getStatistic(this.currentCID, start, stop, points)
    if (v instanceof ErrorResponse) {
      const error = v as ErrorResponse;
    }

    let optionSystem = {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      scales: {
        yAxes: [{
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value + '%';
            }
          }
        }]
      }
    }
    v.then((v: StatisticModel) => {
      let ctx = this.chartSystemRef.nativeElement

      if (this.chartSystem) {
        this.chartSystem.destroy()
      }

      this.chartSystem = new Chart(ctx, {
        type: 'line',
        data: {
          labels: chartLabels,
          datasets: [
            {
              label: 'mem',
              data: v.system.mem,
              backgroundColor: 'rgba(0,181,238,0.0)',
              borderColor: 'rgba(0,181,238,1.0)'
            },
            {
              label: 'cpu',
              data: v.system.cpu,
              backgroundColor: 'rgba(151,198,107,0.0)',
              borderColor: 'rgba(151,198,107,1.0)'
            }
          ]
        },
        options: optionSystem
      });
    })

  }


  public checkName(cid: string) {
    this.keendnsList = []
    this.keendnsSelected = ''
    this.errorKeendnsGetList = false
    this.isKeendnsLoaded = false

    this.profileService.checkName(cid, this.keendnsName).then((r: DomainModel[]) => {
      if (r instanceof ErrorResponse) {
        const re = r as ErrorResponse;
        if (re.code === 401 || re.code === 403) {
          this.logout();
        } else if (re.code === 409) {
          this.errorKeendnsGetList = 'Invalid domain name.'
        } else {
          this.errorKeendnsGetList = 'Sorry. Failed to get the list of available domains.'
        }
        this.isKeendnsLoaded = true

        return
      }
      this.keendnsList = r
      this.isKeendnsLoaded = true
    }).catch(() => {
      this.errorKeendnsGetList = true;
    });
  }

  public keendnsListIsEmpty(): boolean {
    return this.keendnsList.length === 0 && this.isKeendnsLoaded
  }

  public openModalKeenDNS(cid: string) {
    this.keendnsCID = cid
    this.keendnsList = []
    this.keendnsName = ''
    this.keendnsSelected = ''
    this.errorKeendnsGetList = null
    this.isKeendnsLoaded = false


    const config = new TemplateModalConfig<IContext, string, string>(this.modalKeenDNS);
    config.closeResult = 'closed!';


    this.modalService
      .open(config)
      .onApprove(result => {
        this.isLoading = true
        let bookingForm = {
          cid: cid,
          name: this.keendnsName,
          domain: this.keendnsSelected
        }
        this.profileService.bookingName(bookingForm).finally(() => {
          setTimeout(() => {
            this.ngOnInit()
            this.isLoading = false
          }, 1000)
        })
      })
      .onDeny(result => {
        // this.cleanDeviceAddForm();
      });
  }

  public openModalLogs(logs) {
    const config = new TemplateModalConfig<IContext, string, string>(this.modalLogs);
    config.closeResult = 'closed!';
    config.isFullScreen = true;

    this.logslist = logs.show.log

    this.modalService
      .open(config)
  }



  selftest() {
    this.isLoading = true
    this.profileService.selftest(this.currentCID).then((r: string) => {
      var blob = new Blob([r], { type: "text/plain;charset=utf-8" });
      saveAs(blob, "selftest.xml")
    }).catch((re: HttpErrorResponse) => {
      switch (re.status) {
        case 401:
        case 403:
          this.logout;
          return
        case 400:
          alert('Failed to download selftest from router')
      }
    }).finally(() => {
      this.isLoading = false
    });
  }

  isNewReleases() {
    if (this.device['ndmtitle'] && this.device['ndmtitle']) {
      return this.device['ndmtitle'][0] >= 3 && this.device['ndmtitle'][2] > 1
    }
  }

  logs() {
    this.isLoading = true
    this.profileService.logs(this.currentCID).then((r: Object) => {
      // this.openModalTextDisplay(r)
      this.openModalLogs(r)
    }).catch((re: HttpErrorResponse) => {
      switch (re.status) {
        case 401:
        case 403:
          this.logout;
          return
        case 400:
          alert('Failed to download logs from router')
      }
    }).finally(() => {
      this.isLoading = false
    });;
  }

  reboot() {
    this.isLoading = true
    this.profileService.reboot(this.currentCID).catch((re: HttpErrorResponse) => {
      switch (re.status) {
        case 401:
        case 403:
          this.logout;
          return
        case 400:
          alert('Failed reboot router')
      }
    }).finally(() => {
      this.isLoading = false
    });;
  }

  openDeleteDevice(cid: String) {
    let str1: string;
    let str2: string;


    this.translate.get('profile.remove_device').subscribe((res: string) => {
      str1 = res;
    });

    this.translate.get('profile.confirm_remove_device').subscribe((res: string) => {
      str2 = res;
    });


    this.modalService.open(
      new ConfirmModal(
        str1, str2,
        'tiny'
      )
    ).onApprove(result => {
      this.deleteDevice(cid);
    })
      .onDeny(result => { });
  }

  deleteDevice(cid: String) {
    this.profileService.deleteDevice(cid)
      .then((r: ApiMessage) => {
        this.router.navigate(['/']);
      }).catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        }
      });
  }

  responseIsError(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code >= 400) {
        this.router.navigate(['/networks'])
      }
    }
  }
}

function bytesToSize(bytes) {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return ''
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

