/**
 * Created by belkin on 09/01/18.
 */
export class UserModel {
  constructor(public uid: string = '',
              public email: string = '',
              public services: Service[] = [],
              public locale: string = '',
              public name: string = '',
              public phone: string = '',
              public subscriptions: Object = {'all': false, 'actions': false, 'events': false},
            ) {
  }
}

class Service {
  public name: string;
  public url: string;
}
