import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorResponse } from '../../../../models/error-response.model';
import { Chart } from 'chart.js';
import { ProfileService } from '../../../../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { PieChartComponent } from '../../../../components/pie-chart/pie-chart.component';
import { CDTrafficItemModel } from '../../../../models/traffic.model';

const _10m = 1000 * 60 * 10
const _1h = 1000 * 60 * 60
const _12h = _1h * 12
const _24h = _1h * 24
const _7d = _24h * 7
const _1m = _24h * 30

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-familyProfile',
  templateUrl: './familyProfile.component.html',
  styleUrls: ['./familyProfile.component.sass']
})
export class FamilyProfileComponent implements OnInit {
  @ViewChild('chartTraffic') chartTrafficRef: ElementRef;
  @ViewChild(PieChartComponent) pieChartComponent: PieChartComponent;

  isLoading = false;

  chartSystem
  chartTraffic

  public curentFilterBy: string
  public chartLabels: string[] = []

  fpUID = {};
  fp = {}
  networkUID = ''
  network = {}

  @ViewChild('modalUnsetCD')
  public modalUnsetCD: ModalTemplate<IContext, string, string>;
  unsetDevices = []


  constructor(
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private authService: AuthService,
    public modalService: SuiModalService,
    public translate: TranslateService
  ) {
    this.fpUID = this.route.snapshot.paramMap.get('fpuid');
    this.networkUID = this.route.snapshot.paramMap.get('networkuid');
  }

  ngOnInit() {

    this.updateFP()
  }

  updateFP() {
    this.isLoading = true;

    Promise.all([
      this.profileService.getFamilyProfile(this.networkUID, this.fpUID)
    ])
      .then((values: Object[]) => {
        this.responseIsError(values[0])
        let resp = values[0] as Object;
        this.fp = resp['fp'];
        this.network = resp['network']
        this.setCurrentFilter('24h')

        this.isLoading = false;
      }).catch((re: HttpErrorResponse) => {
        this.router.navigate(['/networks']);
      });
  }


  openUnsetCD(cdlist) {
    const config = new TemplateModalConfig<IContext, string, string>(this.modalUnsetCD);
    config.closeResult = 'closed!';
    config.isFullScreen = true;

    this.unsetDevices = cdlist

    this.modalService
      .open(config)


  }

  getUnsetCD() {
    this.isLoading = true
    this.profileService.getUnsetCDFromFamilyProfile(this.networkUID, this.fpUID).then((r: Object) => {
      this.openUnsetCD(r)
    }).catch((re: HttpErrorResponse) => {
      switch (re.status) {
        case 401:
        case 403:
          this.logout;
          return
        case 400:
          alert('Failed to download logs from router')
      }
    }).finally(() => {
      this.isLoading = false
    });;
  }

  updateUnsetCD() {
    this.isLoading = true
    this.profileService.getUnsetCDFromFamilyProfile(this.networkUID, this.fpUID).then((r: Object) => {
      this.unsetDevices = r as Array<Object>
    }).catch((re: HttpErrorResponse) => {
      switch (re.status) {
        case 401:
        case 403:
          this.logout;
          return
        case 400:
          alert('Failed to download logs from router')
      }
    }).finally(() => {
      this.isLoading = false
    });
  }

  deleteCD(mac) {
    this.isLoading = true;

    this.profileService.deleteCDfromFamilyProfile(this.networkUID, this.fpUID, mac).finally(() => {
      this.updateFP()
    })
  }

  setToFP(mac) {
    this.isLoading = true;

    this.profileService.setCDtoFamilyProfile(this.networkUID, this.fpUID, mac).finally(() => {
      this.updateUnsetCD()
      this.updateFP()
    })
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  setCurrentFilter(s: string) {
    this.pieChartComponent.setCurrentFilter(s)

    // this.chartLabels = []
    this.curentFilterBy = s

    let macarr = []

    if (this.fp['connectedDevices']) {
      for (let cd of this.fp['connectedDevices']) {
        macarr.push(cd.mac)
      }
    }



    let v = this.dataService.getFPStatistic(this.networkUID, macarr, this.curentFilterBy)
    if (v instanceof ErrorResponse) {
      const error = v as ErrorResponse;
    }

    let optionTraffic = {
      tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function (tooltipItems, data) {
            return data.datasets[tooltipItems.datasetIndex].label + ': ' + bytesToSize(tooltipItems.yLabel)
          }
        }
      },
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return bytesToSize(value);
            }
          }
        }]
      }
    }


    v.then((v: CDTrafficItemModel[]) => {
      let ctx2 = this.chartTrafficRef.nativeElement

      if (this.chartTraffic) {
        this.chartTraffic.destroy()
      }

      var rxdata = [];
      var txdata = [];
      var labels = [];


      v.forEach((v, i, arr) => {
        rxdata.push(v.rx)
        txdata.push(v.tx)

        var date = new Date(v.ts)
        var label: string;

        switch (this.curentFilterBy) {
          case "10min":
          case "60min":
          case "24h":
            var hours = date.getHours();
            var minutes = "0" + date.getMinutes();
            label = hours + ':' + minutes.substr(-2);
            break
          case "7d":
          case "30d":
          case "12m":
            var day = "0" + date.getDate();
            var month = "0" + (date.getMonth() + 1);
            label = day.substr(-2) + '.' + month.substr(-2)
            break
        }
        labels.push(label)
      })

      this.chartTraffic = new Chart(ctx2, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'download',
              data: rxdata,
              backgroundColor: 'rgba(0,181,238,0.2)',
              borderColor: 'rgba(0,181,238,0.2)'
            },
            {
              label: 'upload',
              data: txdata,
              backgroundColor: 'rgba(151,198,107,0.2)',
              borderColor: 'rgba(151,198,107,0.2)'
            }
          ]
        },
        options: optionTraffic
      });
    }).catch((v) => {
      let ctx2 = document.getElementById("chartTraffic")
      this.chartTraffic = new Chart(ctx2, {
        type: 'bar',
        data: {
          labels: [],
          datasets: [
            {
              label: 'download',
              data: [],
              backgroundColor: 'rgba(0,181,238,0.5)',
              borderColor: 'rgba(0,181,238,0.5)'
            },
            {
              label: 'upload',
              data: [],
              backgroundColor: 'rgba(151,198,107,0.5)',
              borderColor: 'rgba(151,198,107,0.5)'
            }
          ]
        },
        options: optionTraffic
      });

    })
  }


  responseIsError(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code >= 400) {
        this.router.navigate(['/networks'])
      }
    }
  }



  getIconIMG(icon: string, active: boolean) {
    let str = ''
    switch (icon.toLowerCase()) {
      case 'mobile':
        str = 'phone'
        break
      case 'notebook':
        str = 'laptop'
        break
      case 'desktop':
        str = 'computer'
        break
      case 'gameconsole':
        str = 'gamepad'
        break
      case 'tablet':
        str = 'pad'
        break
      case 'camera':
        str = 'camera'
        break
      case 'router':
        str = 'router'
        break
      case 'tv':
        str = 'tv'
        break
      case 'printerfax':
        str = 'printer'
        break
      case 'mediaplayer':
        str = 'mediaplayer'
        break
      case 'nas':
        str = 'storage'
        break
      case 'guest':
        str = 'guest'
        break
      case 'speakeramp':
        str = 'speakeramp'
        break
      case 'networkequipment':
        str = 'networkequipment'
        break
      case 'server':
        str = 'server'
        break
      case 'audiovideo':
        str = 'audiovideo'
        break
      case 'control':
        str = 'controler'
        break
      case 'customdevice':
        str = 'customdevice'
        break
      case 'audioplayer':
        str = 'audioplayer'
        break
      case 'voip':
        str = 'voip'
        break
      case 'wearable':
        str = 'wearable'
        break
      case 'healthcare':
        str = 'healthcare'
        break
      case 'heating':
        str = 'heating'
        break
      case 'homeautomation':
        str = 'home'
        break
      case 'appliance':
        str = 'appliance'
        break
      case 'lighting':
        str = 'light'
        break
      case 'robot':
        str = 'robot'
        break
      case 'securitysystem':
        str = 'security'
        break
      case 'sensor':
        str = 'sensor'
        break
      case 'smartpowerplug':
        str = 'wallsocket'
        break
      case 'smartwatch':
        str = 'smartwatch'
        break
      case 'generic':
        str = 'generic'
        break
      default:
        str = 'generic'
        break
    }
    if (active) {
      str += '-active'
    }

    return '/assets/icons/' + str + '.svg'
  }
}

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return ''
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

