/**
 * Created by belkin on 08/01/18.
 */
import * as jwt from 'jwt-decode'
import {JWTAuthModel} from '../models/jwt-auth.model';

export class JWT {
  static parse(token): JWTAuthModel {
    return jwt(token);
  }
}
