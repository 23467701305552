import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserModel } from '../../models/user.model';
import { ProfileService } from '../../../../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiMessage } from '../../../../models/api-message.model';
import { ErrorResponse } from '../../../../models/error-response.model';
import { TranslateService } from '@ngx-translate/core';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { environment } from '../../../../../environments/environment';

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass']
})
export class AccountComponent implements OnInit {
  @ViewChild('userName') userNameRef: ElementRef;
  @ViewChild('oldPassword') oldPasswordRef: ElementRef;
  @ViewChild('phone') phoneRef: ElementRef;
  @ViewChild('modalChangePassword')
  public modalTemplate: ModalTemplate<IContext, string, string>;

  user: UserModel = new UserModel();
  state = new AccountState();
  phoneMask: any[] = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  isEditName = false;
  isEditPhone = false;

  isDisplayHookPassword = false;
  isDisplayHookPhone = false;

  isLoading = false;
  submitting = false;
  error = '';
  isPasswordCompare = true;
  message = '';

  languages = new Map([
    ['en', 'English'],
    ['tr', 'Türkçe'],
    ['ru', 'Русский'],
    ['fr', 'Français'],
    ['de', 'Deutsch'],
    ['it', 'Italiano'],
    ['pl', 'Polski'],
    ['pt', 'Português'],
    ['es', 'Español'],
    ['sv', 'Svenska'],
  ]);

  constructor(private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    public modalService: SuiModalService,
    private route: ActivatedRoute
  ) {}

  public openModalChangePassword() {
    const config = new TemplateModalConfig<IContext, string, string>(this.modalTemplate);

    config.closeResult = 'closed!';

    this.modalService
      .open(config)
      .onApprove(result => {
        this.savePassword();
        this.state = new AccountState();
      })
      .onDeny(result => {
        this.state = new AccountState();
      });
  }

  settingURL = 'https://account.keenetic.com/auth/realms/users/account/'

  ngOnInit() {
    this.isLoading = true;

    Promise.all([
      this.profileService.getUserInfo()
    ])
      .then((values: Object[]) => {
        if (this.checkGetResponse(values[0])) {
          return;
        }
        const u = values[0] as UserModel;

        if (!u) {
          return;
        }

        this.user = u;
        this.translate.use(this.user.locale);

        this.isLoading = false;
      }).catch((o)=>{
        this.logout()
      });

      if(!environment.production){
        this.settingURL = 'https://account.teenetic.xyz/realms/users/account/'
      }
  }

  checkGetResponse(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code === 403 || error.code === 401) {
        this.logout();
      }
      return true;
    }
    return false;
  }

  changeLanguage(lang) {
    this.user.locale = lang;
    this.updateUserInfo();
    this.translate.use(lang);
  }

  editName() {
    this.isEditName = true;
    setTimeout(() => {
      this.userNameRef.nativeElement.focus();
    }, 100);
  }

  saveName() {
    this.submitting = true;
    this.profileService.updateUserInfo(this.user)
      .then((r: ApiMessage) => {
        this.message = r.message;
        this.isEditName = false;
        setTimeout(() => {
          this.message = '';
          this.submitting = false;
        }, 1500);
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
        this.submitting = false;
        setTimeout(() => {
          this.error = '';
        }, 2000);
      });
  }

  saveProfile() {
    this.submitting = true;
    this.profileService.updateUserInfo(this.user)
      .then((r: ApiMessage) => {
      })
      .catch((r: HttpErrorResponse) => {
        alert(r.error.error);
      });
  }

  deleteAccount() {
    if (confirm('Are you sure you want to delete your account?')){
      this.profileService.deleteUser().then((r) => {
        this.authService.clear();
        window.location.href = '/'
      });
    }
  }


  emptyOldPassword() {
    if (!this.state.oldPassword) {
      this.state = new AccountState();
    }
  }

  savePassword() {
    if (
      !this.state.newPassword ||
      !this.state.confirmPassword ||
      this.state.newPassword !== this.state.confirmPassword
    ) {
      this.isPasswordCompare = false;
      return;
    }
    this.isPasswordCompare = true;
    this.profileService.changePassword({ old_password: this.state.oldPassword, new_password: this.state.newPassword })
      .then((r: ApiMessage) => {
        this.message = r.message;
        this.isDisplayHookPassword = true;
        setTimeout(() => {
          this.isDisplayHookPassword = false;
          this.message = '';
            this.state = new AccountState();
        }, 2000);
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
        this.isDisplayHookPassword = true;
        setTimeout(() => {
          this.isDisplayHookPassword = false;
          this.error = '';
        }, 2000);
      });
  }

  editPhone() {
    this.isEditPhone = true;
    setTimeout(() => {
      this.phoneRef.nativeElement.focus();
    }, 1000);
  }

  savePhone() {
    this.profileService.updateUserInfo(this.user)
      .then((r: ApiMessage) => {
        this.message = r.message;
        this.isDisplayHookPhone = true;
        this.isEditPhone = false;
        setTimeout(() => {
          this.isDisplayHookPhone = false;
          this.message = '';
          this.submitting = false;
        }, 2000);
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
        this.isDisplayHookPhone = true;
        this.submitting = false;
        setTimeout(() => {
          this.isDisplayHookPhone = false;
          this.error = '';
        }, 2000);
      });
  }

  updateUserInfo() {
    this.submitting = true;
    this.profileService.updateUserInfo(this.user)
      .then((r: ApiMessage) => {
        this.message = r.message;
        setTimeout(() => {
          this.submitting = false;
          this.message = '';
        }, 1500);
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
        this.submitting = false;
        setTimeout(() => {
          this.error = '';
        }, 2000);
      });
  }

  logout() {
    this.authService.logout();
    window.location.href = '/api/v1/auth/logout'    
  }

  onKeyup(e: KeyboardEvent, confirmCallbackName, cancelCallbackName?): void {
    if (e.code === 'Escape') {
      this[cancelCallbackName]();
    }
    if (e.code === 'Enter') {
      if (this.submitting) {
        return;
      }
      this[confirmCallbackName]();
    }

    this.error = '';
    this.message = '';
  }

  onBlur(e: Event, callbackName): void {
    if (this.submitting) {
      return;
    }
    this[callbackName]();
    this.error = '';
    this.message = '';
  }
}

class AccountState {
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  addesServiceTag = '';
}

