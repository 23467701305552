import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';

import {AuthComponent} from './auth.component';
import {AuthService} from '../../services/auth.service';
import {AuthRoutingModule} from './auth-routing.module';

import { TranslateModule } from '@ngx-translate/core';
import { SuiSearchModule } from 'ng2-semantic-ui';
import { ProcessComponent } from './process/process.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    HttpClientModule,
    AuthRoutingModule,
    SuiSearchModule
  ],
  declarations: [
    AuthComponent,
    ProcessComponent
  ],
  providers: [AuthService]
})
export class AuthModule {
}
