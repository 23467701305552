import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountComponent} from './screens/account/account.component';
import {ProfileRoutingModule} from './profile-routing.module';
import {ProfileService} from '../../services/profile.service';
import {DropdownComponent} from '../../components/dropdown/dropdown.component';
import {PieChartComponent} from '../../components/pie-chart/pie-chart.component';
import {FormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';

import { TranslateModule } from '@ngx-translate/core';
import { LocationsComponent } from './screens/locations/locations.component';
import { ProfileMenuComponent } from '../../components/profile-menu/profile-menu.component';
import { WarrantiesComponent } from './screens/warranties/warranties.component';
import { SecurityComponent } from './screens/security/security.component';
import { EventsComponent } from './screens/events/events.component';
import { SuiModule, SuiTabsModule } from 'ng2-semantic-ui';
import { ConfirmModalComponent } from '../../components/modal-confirm/modal-confirm.component';
import { DeviceComponent } from './screens/device/device.component';
import { DataService } from '../../services/data.service';
import { NotificationsComponent } from './screens/notifications/notifications.component';
import { ConnectedDevicesComponent } from './screens/connectedDevices/connectedDevices.component';
import { FamilyProfilesComponent } from './screens/familyProfiles/familyProfiles.component';
import { FamilyProfileComponent } from './screens/familyProfile/familyProfile.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ProfileRoutingModule,
    TextMaskModule,
    SuiModule,
    SuiTabsModule,
  ],
  declarations: [
    AccountComponent,
    LocationsComponent,
    ProfileMenuComponent,
    DropdownComponent,
    PieChartComponent,
    ConfirmModalComponent,
    WarrantiesComponent,
    ConnectedDevicesComponent,
    NotificationsComponent,
    SecurityComponent,
    DeviceComponent,
    FamilyProfilesComponent,
    FamilyProfileComponent,
    EventsComponent
  ],
  entryComponents: [ConfirmModalComponent],
  providers: [
    ProfileService,
    DataService
  ]
})
export class ProfileModule {
}
