import { Component, OnInit, ViewChild } from '@angular/core';
import { WarrantyModel } from '../../../../models/warranty.model';
import { ProfileService } from '../../../../services/profile.service';
import { ApiMessage } from '../../../../models/api-message.model';
import { ErrorResponse } from '../../../../models/error-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ConfirmModal } from '../../../../components/modal-confirm/modal-confirm.component';
import { TranslateService } from '@ngx-translate/core';

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-warranties',
  templateUrl: './warranties.component.html',
  styleUrls: ['./warranties.component.sass']
})
export class WarrantiesComponent implements OnInit {
  isLoading = false;
  warranties: WarrantyModel[] = [];
  servicetagForm = '';

  @ViewChild('modalAddWarranty')
  public modalTemplate: ModalTemplate<IContext, string, string>;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    public modalService: SuiModalService,
    public translate: TranslateService
  ) {}

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  public openModalAddWarranty() {
    const config = new TemplateModalConfig<IContext, string, string>(this.modalTemplate);

    config.closeResult = 'closed!';

    this.modalService
      .open(config)
      .onApprove(result => {
        this.addWarranty();
        this.servicetagForm = '';
      })
      .onDeny(result => {
        this.servicetagForm = '';
      });
  }
  addWarranty() {
    this.profileService.addWarranty(this.servicetagForm)
      .then((r: ApiMessage) => {
        this.profileService.getWarranties().then((rc: WarrantyModel[]) => {
          if (rc instanceof ErrorResponse) {
            const re = rc as ErrorResponse;
            if (re.code === 401 || re.code === 403) {
              this.logout();
            }
          }
          this.warranties = rc;
        });
      }
      ).catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        } else if (re.status === 409) {
          alert('Warrenty already exists!');
        } else if (re.status === 400) {
          alert('Invalid input!');
        }
      });

      this.servicetagForm = '';
  }

  openDeleteWarranty(id: String) {
    let str1: string;
    let str2: string;

    this.translate.get('profile.remove_warranty').subscribe((res: string) => {
      str1 = res;
    });

    this.translate.get('profile.confirm_remove_warranty').subscribe((res: string) => {
      str2 = res;
    });
    this.modalService.open(new ConfirmModal(str1, str2, 'tiny'))
        .onApprove(result => {
          this.deleteWarranty(id);
        })
        .onDeny(result => {});
  }

  deleteWarranty(id: String) {
    this.profileService.deleteWarranties(id)
      .then((r: ApiMessage) => {
        this.profileService.getWarranties().then((rc: WarrantyModel[]) => {
          if (rc instanceof ErrorResponse) {
            const re = rc as ErrorResponse;
            if (re.code === 401 || re.code === 403) {
              this.logout();
            }
          }
          this.warranties = rc;
        });
      }
      ).catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        } else if (re.status === 404) {
          alert('Warrenty already exists!');
        }
      });
  }

  ngOnInit() {
    this.isLoading = true;
    Promise.all([
      this.profileService.getWarranties()
    ])
      .then((values: Object[]) => {
        this.warranties = values[0] as WarrantyModel[];
        this.isLoading = false;
      });
  }

}
