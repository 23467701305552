import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { ErrorResponse } from '../../../../models/error-response.model';
import { AuthService } from '../../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SuiModalService } from 'ng2-semantic-ui';
import { TranslateService } from '@ngx-translate/core';
import { PieChartComponent } from '../../../../components/pie-chart/pie-chart.component';

@Component({
  selector: 'app-connectedDevices',
  templateUrl: './connectedDevices.component.html',
  styleUrls: ['./connectedDevices.component.sass']
})


export class ConnectedDevicesComponent implements OnInit {
  @ViewChild(PieChartComponent) pieChartComponent: PieChartComponent;
  public curentFilterBy = '24h'

  cdList: Object[] = [];
  archivedList: Object[] = [];

  isLoading = false;
  interval: any;

  network = "";

  networkModel = {};

  archivedOpened = false;

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  checkHideDevices(e) {
    this.isLoading = true;

    var promise: Promise<Object>
    if (e.target.checked) {
      promise = this.profileService.setNetworkSetting(this.network, 60 * 60 * 24 * 7)
    } else {
      promise = this.profileService.setNetworkSetting(this.network, -1)
    }

    promise.finally(() => {
      this.refreshData().finally(() => {
        this.isLoading = false;
      })
    })
  }

  checkTrackDevice(mac, e) {
    if (e.target.checked) {
      this.profileService.setTrackCD(this.network, mac, true)
    } else {
      this.profileService.setTrackCD(this.network, mac, false)
    }
  }

  wakeup(cd) {
    if (cd['is_loading']) {
      return
    }

    cd['is_loading'] = true;

    this.profileService.wakeup(this.network, cd['mac']).finally(() => {
      cd['is_loading'] = false
    });
  }

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public modalService: SuiModalService,
    public translate: TranslateService,
  ) {
    this.network = this.route.snapshot.paramMap.get('networkuid');
  }


  ngOnInit() {
    this.isLoading = true;
    this.refreshData().then(() => {
      this.pieChartComponent.setCurrentFilter(this.curentFilterBy)
    })
    this.pieChartComponent.setCurrentFilter(this.curentFilterBy)
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  refreshData() {
    return Promise.all([
      this.profileService.getCD(this.network)
    ])
      .then((values: Object[]) => {
        if (this.responseIsNotAuth(values[0])) {
          return;
        }
        this.cdList = values[0]['cd'] as Object[];
        this.networkModel = values[0]['network'] as Object
        this.isLoading = false;
      });
  }

  responseIsNotAuth(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code === 403 || error.code === 401) {
        this.logout();
      }
      return true;
    }
    return false;
  }

  toggleArchived(){
    this.archivedOpened = !this.archivedOpened
    if (this.archivedOpened){
      this.isLoading=true;

      Promise.all([
        this.profileService.getArchived(this.network)
      ])
        .then((values: Object[]) => {
          this.isLoading = false;

          if (this.responseIsNotAuth(values[0])) {
            return;
          }
          this.archivedList = values[0]['cd'] as Object[];
          this.networkModel = values[0]['network'] as Object
        });
    }
  }
}

