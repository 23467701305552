/**
 * Created by belkin on 28/12/17.
 */
import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {AuthComponent} from "./auth.component";
import { ProcessComponent } from "./process/process.component";

const authRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AuthComponent
  },
  {
    path: 'process',
    pathMatch: 'full',
    component: ProcessComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}

