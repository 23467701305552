import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppGuard } from './app.guard';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './modules/auth/auth.module#AuthModule'
  },
  {
    path: 'recovery',
    loadChildren: './modules/recovery/recovery.module#RecoveryModule'
  },
  {
    path: '',
    loadChildren: './modules/profile/profile.module#ProfileModule',
    canActivate: [AppGuard]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        preloadingStrategy: PreloadAllModules
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
