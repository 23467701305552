import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationModel } from '../../../../models/notifications.model';
import { forEach } from '@angular/router/src/utils/collection';

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass']
})
export class NotificationsComponent implements OnInit {
  newHook = {
    event: '',
    is_email: false,
    is_push: false,
    is_telegram: false,
    endpoint: '',
  }

  hooks = {
    'device_online': {
      name: 'Device online',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_offline': {
      name: 'Device offline',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_switch_connection': {
      name: 'Switch connections',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_started': {
      name: 'Device start',
      isEmail: false,
      isPush: false,
      webhook: '',
    },
    'device_updated_release': {
      name: 'Updating KeeneticOS',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'fp_state_changed': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_wifi_enabled': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_wifi_disabled': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_neighbour': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_neighbour_appeared': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
  };

  telegramCode = '';
  isLoading = false;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    public translate: TranslateService
  ) { }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  ngOnInit() {
    this.initNameEvent()
    this.updateHooksList()
  }

  generateTelegram() {
          this.profileService.generateTelegram().then(()=>{
            this.updateHooksList()
          }).catch(()=>{
            alert('Error of generating telegram code')
          })
  }

  updateHooksList() {
    this.isLoading = true;

    // Promise.all([
    //   this.profileService.getNotifications(),
    //   this.profileService.getBots(),
    // ])
    //   .then((values) => {
    //     let h = values[0] as Map<string, {}>
    //     Object.keys(h).map((objectKey, index, arr) => {
    //       var o = this.hooks[objectKey]
    //       if (!o) {
    //         return
    //       }
    //       o.isPush = h[objectKey]['is_push']
    //       o.isEmail = h[objectKey]['is_email']
    //       o.isTelegram = h[objectKey]['is_telegram']
    //       o.webhook = h[objectKey]['webhook']
    //       this.hooks[objectKey]=o
    //     });

    //     let bots = values[1] as Map<string, string>
    //     this.telegramCode = bots['telegram']
    //   })
    //   .catch((re: HttpErrorResponse) => {
    //     if (re.status === 401 || re.status === 403) {
    //       this.logout();
    //     }
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  }

  isValidURL(url) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i');
    return pattern.test(url);
  }

  initNameEvent() {
    for (let h in this.hooks) {
      this.translate.get('profile.' + h).subscribe((res: string) => {
        this.hooks[h].name = res
      });
    }
  }

  saveHook(code) {
    if (this.hooks[code].webhook !== '' && !this.isValidURL(this.hooks[code].webhook)) {
      alert("Endpoint for hook is invalid")
      return
    }
    this.profileService.changeNotification("", code, this.hooks[code]).then(() => {
      this.updateHooksList()
    })
  }
}
