export class DeviceModel {
  constructor(
    public name: string = '',
    public network: string = '',
    public cid: string = '',
    public login: string = '',
    public password: string = ''
  ) {
  }
}
