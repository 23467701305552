import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JWT } from '../helpers/jwt';
import { StatisticModel } from '../models/statistis.model';
import { ErrorResponse } from '../models/error-response.model';
import { HttpServiceBase } from './base-service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataService extends HttpServiceBase {
  private headers: HttpHeaders;
  private uid: string;

  constructor(private http: HttpClient) {
    super()
  }

  // const secondsCounter = interval(1000);

  // secondsCounter.subscribe(n => console.log(`It's been ${n} seconds since subscribing!`));

  getStatistic(cid: string, start, stop, points: number): Promise<StatisticModel | ErrorResponse> {
    this.setToken();
    return this.http
      .get("https://"+this.getDataNode()+"/stat/stat?start=" + start + "&stop=" + stop + "&cid=" + cid + "&points=" + points, {headers: this.headers} )
      .toPromise()
      .then(r => r as StatisticModel)
      .catch(r => this.wrapHttpError(r));
  }

  getStatisticV2(cid, period: string): Promise<Object | ErrorResponse> {
    this.setToken();
    return this.http
      .get("https://"+this.getDataNode()+"/stat/device?cid=" + cid + "&period=" + period, {headers: this.headers})
      .toPromise()
      .then(r => r as StatisticModel)
      .catch(r => this.wrapHttpError(r));
  }

  getFPStatistic(network: string, mac: Array<string>, period: string): Promise<Object | ErrorResponse> {
    this.setToken();
    return this.http
      .post("https://"+this.getDataNode()+"/stat/fp", {
        network: network,
        period: period,
        macs: mac,
      }, {headers: this.headers})
      .toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  getCDTopV2(network, period: string): Promise<Object | ErrorResponse> {
    this.setToken();
    return this.http
      .get("https://"+this.getDataNode()+"/stat/cd/top?network="+network+"&period="+period, {headers: this.headers})
      .toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  getEvents(locale: string, uid: string): Promise<Object[] | ErrorResponse> {
    this.setToken();
    return this.http
      .get("https://"+this.getDataNode()+"/events?network=" + uid+"&locale="+locale, {headers: this.headers})
      .toPromise()
      .then(r => r as Object[])
      .catch(r => this.wrapHttpError(r));
  }

  deleteEventsList(network: String): Promise<Object[] | ErrorResponse> {
    this.setToken();
    return this.http.delete(`https://`+this.getDataNode()+`/events?network=` + network, {headers: this.headers})
      .toPromise()
      .then(r => r as Object[])
      .catch(r => this.wrapHttpError(r));
  }

  getDataNode() {
    if (environment.production) {
      return 'keenetic.cloud/api/v1'
    }
    return 'dev.keenetic.cloud/api/v1'
  }

  setToken() {
    const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
    this.uid = JWT.parse(token).uid;
    this.headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
  }
}

