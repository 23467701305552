/**
 * Created by belkin on 1/13/18.
 */
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorResponse} from '../models/error-response.model';

export class HttpServiceBase {

  constructor() {
  }

  protected wrapHttpError(r: HttpErrorResponse): ErrorResponse {
    return new ErrorResponse(r.error.code, r.error.error);
  }
}
