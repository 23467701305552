/**
 * Created by belkin on 08/01/18.
 */
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class AppGuard {
  constructor(private router: Router) {
  }

  canActivate() {
    if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
      return true;
    }

    this.router.navigate(['/auth']);
    return false;
  }
}
