import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CheckEmail } from './models/check-email';
import { AuthService } from '../../services/auth.service';
import { LoginResponse } from './models/login-response';
import { RegisterResponse } from './models/register-response';
import { JWT } from '../../helpers/jwt';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import {
  HttpErrorResponse
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService } from 'ng2-semantic-ui';

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.sass']
})
export class AuthComponent implements OnInit {

  @ViewChild('email') emailRef: ElementRef;
  @ViewChild('password') passwordRef: ElementRef;
  @ViewChild('name') nameRef: ElementRef;
  @ViewChild('registerPassword') registerPasswordRef: ElementRef;
  @ViewChild('confirmPassword') confirmPasswordRef: ElementRef;
  @ViewChild('countries') countriesRef: ElementRef;
  @ViewChild('modalEULA')
  public modalTemplate: ModalTemplate<IContext, string, string>;


  state = new AuthState();

  isLoading = false;
  submitting = false;
  error = '';
  message = '';


  emailIsExists = false;
  showPassword = false; // if true then show a password in text field

  screenStep = 0;

  isEulaPage = false;
  eulaAgree = false;
  eulaText = '';
  eulaVersion = '';
  isLogin = false;

  isDisplayMessageRestore = false;

  countries = new Map();

  authdomain = 'account.keenetic.com'
  redirectdomain = 'keenetic.cloud'

  constructor(private authService: AuthService,
    private router: Router,
    public modalService: SuiModalService,
    private translate: TranslateService) {
  }

  public openEULAModal() {
    const config = new TemplateModalConfig<IContext, string, string>(this.modalTemplate);
    config.closeResult = 'closed!';
    this.modalService
      .open(config)
  }

  ngOnInit(): void {
    if (!environment.production) {
      this.authdomain = 'account.teenetic.xyz'
      this.redirectdomain = 'dev.keenetic.cloud'
    }
    if (this.authService.token) {
      this.router.navigate(['/']);
    }

    this.authService.eula(this.translate.currentLang).then(resp => {
      this.eulaVersion = resp.headers.get("x-file-version")
      this.eulaText = resp.body
    }).catch(()=>{
      this.router.navigate(['/']);
    })

    setTimeout(() => {
      // this.emailRef.nativeElement.focus();
    }, 100);

    this.authService.getCountries(this.translate.currentLang)
      .then((r: Object[]) => {
        this.isLoading = false;
        for (const v of r) {
          this.countries.set(v['code'], v['native']);
        }
      })
      .catch((r: HttpErrorResponse) => {
        console.log('Error:', r);
        this.isLoading = false;
      });
  }

  checkEmail(): void {
    if (!this.state.email){
      return;
    }
    this.isLoading = true;
    // send to api for checks the email
    this.authService.check(this.state.email)
      .then((r: CheckEmail) => {
        this.emailIsExists = r.is_exists;
        this.isLoading = false;
        // if email is exists, then next screen is login form,
        // else form form registration
        if (this.emailIsExists) {
          this.screenStep = 1;
          // set focus on password input
          setTimeout(() => {
            this.passwordRef.nativeElement.focus();
          }, 100);
          return;
        }
        setTimeout(() => {
          this.registerPasswordRef.nativeElement.focus();
        }, 100);
        this.screenStep = 3;
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
        this.isLoading = false;
      });
  }

  login(): void {
    this.authService.login(this.state.email, this.state.password)
      .then((r: LoginResponse) => {
        // save user credentials
        if (this.state.rememberMe) {
          localStorage.setItem('token', r.token);
        } else {
          sessionStorage.setItem('token', r.token);
        }

        localStorage.setItem('cid', JWT.parse(r.token).cid);
        this.authService.setToken(r.token);

        // return to home
        // fix setup token
        setTimeout(() => {
          this.router.navigate(['/']);
          this.isLoading = false;
        }, 1500);
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
        this.isLoading = false;
      });
  }

  next(): void {
    if (
      !this.state.confirmPassword ||
      this.state.password !==
      this.state.confirmPassword
    ) {
      return;
    }
    this.screenStep = 4;

    setTimeout(() => {
      this.nameRef.nativeElement.focus();
    }, 100);
  }

  register(): void {
    if (!this.state.name) {
      return;
    }
    this.isLoading = true;
    this.authService.register(this.state.email, this.state.password, this.state.name, this.eulaVersion)
      .then((r: RegisterResponse) => {
        this.state.uid = r.uid;

        // auto login after registration
        this.authService.login(this.state.email, this.state.password)
          .then((lr: LoginResponse) => {
            localStorage.setItem('token', lr.token);
            localStorage.setItem('cid', JWT.parse(lr.token).cid);
            this.authService.setToken(lr.token);

            setTimeout(() => {
              this.router.navigate(['/']);
              this.isLoading = false;
            }, 500);
          })
          .catch((hr: HttpErrorResponse) => {
            this.error = hr.error.error;
            // return to auth
            this.screenStep = 0
            this.isLoading = false;
          });
      })
      .catch((r: HttpErrorResponse) => {
        this.error = r.error.error;
      });
  }

  restorePassword() {
    this.screenStep = 5
    this.isLoading = true;

    this.authService.restorePassword(this.state.email)
      .then(() => {
        this.isLoading = false;
        this.isDisplayMessageRestore = true;
      })
      .catch((r: HttpErrorResponse) => {
        this.isLoading = false;
        this.error = r.error.error;
      });
  }

  onKeyup(e: KeyboardEvent, callbackName): void {
    if (e.code === 'Enter') {
      this[callbackName]();
    }
    this.error = '';
  }

  nextConfirmPasswordInput(): void {
    this.confirmPasswordRef.nativeElement.focus();
  }

  nextCountries(): void {
    this.countriesRef.nativeElement.focus();
  }

  backToEmail(): void {
    this.isLogin = false;
    this.screenStep = 0;
  }

  get debug(): string {
    return JSON.stringify(this.state);
  }
}

class AuthState {
  email = '';
  password = '';
  name = '';
  confirmPassword = '';
  uid = '';
  rememberMe = true;
}
