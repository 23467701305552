import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { ErrorResponse } from '../../../../models/error-response.model';
import { AuthService } from '../../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModal } from '../../../../components/modal-confirm/modal-confirm.component';
import { SuiModalService } from 'ng2-semantic-ui';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../../services/data.service';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit {
  isLoading = false;
  interval: any;
  networkUID = ''
  network = {};
  events = [];

  str101 = '';
  str102 = '';
  str103 = '';
  str104 = '';
  str105 = '';
  str106 = '';
  str107 = '';
  str108 = '';
  str109 = '';
  str110 = '';
  str111enable = '';
  str111disable = '';
  strClearTtitle = '';
  strClearDesc = '';
  strClearYes = '';
  strClearNo = '';

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  constructor(
    private dataService: DataService,
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    public modalService: SuiModalService,
    // public tabsService: SuiTabsModule,
    public translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    this.networkUID = this.route.snapshot.paramMap.get('networkuid');
    this.translate.get('profile.events_modal_clear_title').subscribe((res: string) => {
      this.strClearTtitle = res;
    });
    this.translate.get('profile.events_modal_clear_description').subscribe((res: string) => {
      this.strClearDesc = res;
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.refreshEvents()
    this.initNameEvent()
    this.updateHooksList()
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  tsToDateTime(unixtimestamp) {
    // Months array
    const months_arr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(unixtimestamp * 1000);
    const year = date.getFullYear();
    const month = months_arr[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const seconds = '0' + date.getSeconds();
    const convdataTime = month + '-' + day + '-' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return convdataTime;
  }

  responseIsNotAuth(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code === 403 || error.code === 401) {
        this.logout();
      }
      return true;
    }
    return false;
  }

  responseIsError(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code >= 400) {
        this.router.navigate(['/networks'])
      }
    }
  }

  cleanConfirmModal() {
    let str1: string;
    let str2: string;

    this.modalService.open(
      new ConfirmModal(
        this.strClearTtitle, this.strClearDesc,
        'tiny'
      )
    ).onApprove(result => {
      this.dataService.deleteEventsList(this.networkUID)
        .then((values: Object[]) => {
          this.refreshEvents()
        });
    })
      .onDeny(result => { });
  }

  refreshEvents() {
    Promise.all([
      this.profileService.getNetwork(this.networkUID)
    ])
      .then((values: Object[]) => {
        this.responseIsError(values[0])
        this.network = values[0] as Object;

        Promise.all([
          this.dataService.getEvents(this.translate.currentLang, this.networkUID)
        ])
          .then((values: Object[]) => {
            if (this.responseIsNotAuth(values[0])) {
              return;
            }
            this.events = values[0] as Object[];
            this.isLoading = false;
          }).catch(() => {
            this.router.navigate(['/networks']);
          });

        this.isLoading = false;
      })
      .catch((re: HttpErrorResponse) => {
        this.router.navigate(['/networks'])
      });
  }

  // ----------- NEW ------------------
  newHook = {
    event: '',
    is_email: false,
    is_push: false,
    is_telegram: false,
    endpoint: '',
  }

  hooks = {
    'device_online': {
      name: 'Device online',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_offline': {
      name: 'Device offline',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_switch_connection': {
      name: 'Switch connections',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_started': {
      name: 'Device start',
      isEmail: false,
      isPush: false,
      webhook: '',
    },
    'device_updated_release': {
      name: 'Updating KeeneticOS',
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'fp_state_changed': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_wifi_enabled': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_wifi_disabled': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_neighbour': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
    'device_neighbour_appeared': {
      isEmail: false,
      isPush: false,
      isTelegram: false,
      webhook: '',
    },
  };

  telegramCode = '';

  generateTelegram() {
    this.profileService.generateTelegram().then(() => {
      this.updateHooksList()
    }).catch(() => {
      alert('Error of generating telegram code')
    })
  }

  updateHooksList() {
    this.isLoading = true;

    Promise.all([
      this.profileService.getNotifications(this.networkUID),
      this.profileService.getBots(),
    ])
      .then((values) => {
        let h = values[0] as Map<string, {}>
        Object.keys(h).map((objectKey, index, arr) => {
          var o = this.hooks[objectKey]
          if (!o) {
            return
          }
          o.isPush = h[objectKey]['is_push']
          o.isEmail = h[objectKey]['is_email']
          o.isTelegram = h[objectKey]['is_telegram']
          o.webhook = h[objectKey]['webhook']
          this.hooks[objectKey] = o
        });

        let bots = values[1] as Map<string, string>
        this.telegramCode = bots['telegram']
      })
      .catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  isValidURL(url) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i');
    return pattern.test(url);
  }

  initNameEvent() {
    for (let h in this.hooks) {
      this.translate.get('profile.' + h).subscribe((res: string) => {
        this.hooks[h].name = res
      });
    }
  }

  saveHook(code) {
    if (this.hooks[code].webhook !== '' && !this.isValidURL(this.hooks[code].webhook)) {
      alert("Endpoint for hook is invalid")
      return
    }
    this.profileService.changeNotification(this.networkUID, code, this.hooks[code]).then(() => {
      this.updateHooksList()
    })
  }
}

