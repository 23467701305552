import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthService} from './services/auth.service';

import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from './services/profile.service';
import { UserModel } from './modules/profile/models/user.model';
import { ErrorResponse } from './models/error-response.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'app';
  isAuth = false;
  mySubscription: any;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService, 
    private router: Router, 
    private translate: TranslateService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
    this.mySubscription = this.router.events.subscribe((event)=>{
      if (event instanceof NavigationEnd){
        this.router.navigated = false;
      }
    })
  }

  logout() {
    this.authService.logout();
    // this.router.navigate(['/api/v1/auth/logout']);
    window.location.href = '/api/v1/auth/logout'
  }

  checkGetResponse(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code === 403 || error.code === 401) {
        this.logout();
      }
      return true;
    }
    return false;
  }

  ngOnInit(): void { 
    this.translate.addLangs(['en', 'ru', 'tr', "fr",
		"de",
		"it",
		"pl",
		"pt",
		"es",
		"sv"]);
    this.translate.setDefaultLang(getLastLocale());
    const browserLang = this.translate.getBrowserLang();

    this.isAuth =  this.authService.isAuthorized();
    if (this.isAuth) {
      this.profileService.getUserInfo().then((v) => {
          const u = v as UserModel;
          if (!u) {
            this.logout()
            return;
          }
          setLastLocale(u.locale)
          this.translate.use(u.locale);
      }).catch((r)=>{
        this.logout()
      })
    } else {
      this.translate.use(browserLang.match(/en|tr|ru/) ? browserLang : 'en');
    }

  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}

function getLastLocale(){
  const l = localStorage.getItem("locale")
  if (l) {
    return l
  }

  return 'en'
}

function setLastLocale(locale){
  localStorage.setItem("locale", locale) 
}

