import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JWT } from '../helpers/jwt';
import { UserModel } from '../modules/profile/models/user.model';
import { ClientModel } from '../models/client.model';
import { HttpServiceBase } from './base-service';
import { ErrorResponse } from '../models/error-response.model';
import { DeviceModel } from '../models/device.model';
import { WarrantyModel } from '../models/warranty.model';
import { NetworkModel } from '../models/network.model';
import { DomainModel } from '../models/domain.model';
import { NotificationModel } from '../models/notifications.model';


@Injectable()
export class ProfileService extends HttpServiceBase {
  private apiEndpoint = '/api/v1';
  private headers: HttpHeaders;
  private uid: string;

  constructor(private http: HttpClient) {
    super();
  }

  setToken() {
    const token = (localStorage.getItem('token') || sessionStorage.getItem('token'));
    this.uid = JWT.parse(token).uid;
    this.headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
  }

  updateUserInfo(userInfo: UserModel): Promise<Object> {
    this.setToken();

    return this.http.post(`${this.apiEndpoint}/users/${this.uid}`, userInfo, {
      headers: this.headers
    })
      .toPromise();
  }

  deleteUser(): Promise<Object> {
    this.setToken();

    return this.http.delete(`${this.apiEndpoint}/users/${this.uid}`, {
      headers: this.headers
    }).toPromise();
  }


  changePassword(passwords: { new_password: string, old_password: string }): Promise<Object> {
    this.setToken();

    return this.http.post(`${this.apiEndpoint}/auth/pswd`, passwords, {
      headers: this.headers
    }).toPromise();
  }

  getUserInfo(): Promise<UserModel | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/users/${this.uid}`, {
      headers: this.headers
    }).toPromise()
      .then(r => r as UserModel)
      .catch(r => this.wrapHttpError(r));
  }

  getClients(): Promise<ClientModel[] | ErrorResponse> {
    this.setToken();
    return this.http.get(`${this.apiEndpoint}/clients`, {
      headers: this.headers
    })
      .toPromise()
      .then(r => r as ClientModel[])
      .catch(r => this.wrapHttpError(r));
  }

  getCD(uid): Promise<Object[] | ErrorResponse> {
    this.setToken();
    return this.http.get(`${this.apiEndpoint}/connected-devices?uid=` + uid, {
      headers: this.headers
    })
      .toPromise()
      .then(r => r as Object[])
      .catch(r => this.wrapHttpError(r));
  }

  getArchived(uid): Promise<Object[] | ErrorResponse> {
    this.setToken();
    return this.http.get(`${this.apiEndpoint}/archived?uid=` + uid, {
      headers: this.headers
    })
      .toPromise()
      .then(r => r as Object[])
      .catch(r => this.wrapHttpError(r));
  }

  setTrackCD(network, mac, istrack): Promise<Object> {
    this.setToken();
    return this.http.post(`${this.apiEndpoint}/networks/`+network+`/connected-devices`, {
      mac: mac,
      is_track: istrack
    },{
      headers: this.headers
    })
      .toPromise()
  }

  wakeup(network, mac): Promise<Object> {
    this.setToken();
    return this.http.post(`${this.apiEndpoint}/networks/`+network+`/connected-devices/`+mac+`/wakeup`,null,{
      headers: this.headers
    })
      .toPromise()
  }

  selftest(cid): Promise<string | ErrorResponse> {
    this.setToken();
    return this.http.get(`${this.apiEndpoint}/cmd/selftest?cid=` + cid, {
      headers: this.headers,
      responseType: 'text'
    })
      .toPromise();
  }

  logs(cid): Promise<Object | ErrorResponse> {
    this.setToken();
    return this.http.get(`${this.apiEndpoint}/cmd/logs?cid=` + cid, {
      headers: this.headers,
      responseType: 'json'
    })
      .toPromise();
  }

  reboot(cid): Promise<Object | ErrorResponse> {
    this.setToken();
    return this.http.get(`${this.apiEndpoint}/cmd/reboot?cid=` + cid, {
      headers: this.headers,
    })
      .toPromise();
  }

  deleteClient(cid: String): Promise<Object> {
    this.setToken();
    return this.http.delete(`${this.apiEndpoint}/clients/` + cid, {
      headers: this.headers
    })
      .toPromise();
  }

  disconnectClient(cid): Promise<Object> {
    this.setToken();
    return this.http.delete(`${this.apiEndpoint}/clients/${cid}`)
      .toPromise();
  }

  getDevices(): Promise<DeviceModel[] | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/devices`, {
      headers: this.headers
    }).toPromise()
      .then(r => r as DeviceModel[])
      .catch(r => this.wrapHttpError(r));
  }

  checkName(cid, name: string): Promise<DomainModel[] | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/keendns/check-name?cid=` + cid + '&name=' + name, {
      headers: this.headers
    }).toPromise()
      .then(r => r as DomainModel[])
      .catch(r => this.wrapHttpError(r))
  }

  bookingName(obj: Object): Promise<Object> {
    this.setToken()
    return this.http.post(`${this.apiEndpoint}/keendns/booking-name`, obj, {
      headers: this.headers
    })
      .toPromise();
  }

  addDevice(device: DeviceModel): Promise<Object> {
    this.setToken();
    // const body = { cid: cid, name: name, login: login, password: password};

    return this.http.post(`${this.apiEndpoint}/devices`, device, {
      headers: this.headers
    })
      .toPromise();
  }

  deleteDevice(cid: String): Promise<Object> {
    this.setToken();
    return this.http.delete(`${this.apiEndpoint}/devices/` + cid, {
      headers: this.headers
    })
      .toPromise();
  }

  addWarranty(serrviceTag: String): Promise<Object> {
    const body = { service_tag: serrviceTag };
    this.setToken();
    return this.http.post(`${this.apiEndpoint}/warranties`, body, {
      headers: this.headers
    })
      .toPromise();
  }

  getWarranties(): Promise<WarrantyModel[] | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/warranties`, {
      headers: this.headers
    }).toPromise()
      .then(r => r as WarrantyModel[])
      .catch(r => this.wrapHttpError(r));
  }

  getNetworks(): Promise<NetworkModel[] | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks`, {
      headers: this.headers
    }).toPromise()
      .then(r => r as NetworkModel[])
      .catch(r => this.wrapHttpError(r));
  }

  getDevice(cid): Promise<Object | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/devices/` + cid, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  getFamilyProfiles(uid): Promise<Object | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks/`+uid+`/family-profiles`, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  getFamilyProfile(network, uid): Promise<Object | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks/`+network+`/family-profiles/`+uid, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  deleteCDfromFamilyProfile(network, uid, mac): Promise<Object | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks/`+network+`/family-profiles/`+uid+`/deletecd?mac=`+mac, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  getUnsetCDFromFamilyProfile(network, uid): Promise<Array<Object> | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks/`+network+`/family-profiles/`+uid+`/getunsetcd`, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Array<Object>)
      .catch(r => this.wrapHttpError(r));
  }

  setCDtoFamilyProfile(network, uid, mac): Promise<Object | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks/`+network+`/family-profiles/`+uid+`/setcd?mac=`+mac, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  getNetwork(uid): Promise<Object | ErrorResponse> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/networks/` + uid, {
      headers: this.headers
    }).toPromise()
      .then(r => r as Object)
      .catch(r => this.wrapHttpError(r));
  }

  addNetwork(network: NetworkModel): Promise<Object> {
    this.setToken();

    return this.http.post(`${this.apiEndpoint}/networks`, network, {
      headers: this.headers
    })
      .toPromise();
  }

  setNetworkSetting(uid, sec): Promise<Object> {
    let payload = {
      hidden_unregistered: sec,
    }

    this.setToken();
    return this.http.post(`${this.apiEndpoint}/networks/`+uid, payload, {
      headers: this.headers
    })
      .toPromise();
  }

  deleteWarranties(id: String): Promise<Object> {
    this.setToken();
    return this.http.delete(`${this.apiEndpoint}/warranties/` + id, {
      headers: this.headers
    })
      .toPromise();
  }

  getCountries(): Promise<Object[]> {
    this.setToken();

    return this.http.get<Object[]>(`${this.apiEndpoint}/countries-list`, {
      headers: this.headers
    }).toPromise();
  }

  changeNotification(network, code, obj): Promise<Object> {
    let payload = {
      network: network,
      event: code,
      is_email: obj['isEmail'],
      is_push: obj['isPush'],
      is_telegram: obj['isTelegram'],
      endpoint: obj['webhook']
    }

    this.setToken();
    return this.http.post(`${this.apiEndpoint}/notifications`, payload, {
      headers: this.headers
    })
      .toPromise();
  }

  getNotifications(network): Promise<Map<string, NotificationModel> | ErrorResponse> {
    this.setToken()
    return this.http.get(`${this.apiEndpoint}/notifications?network=`+network, {
      headers: this.headers
    }).toPromise().then(r => r as Map<string, NotificationModel>)
      .catch(r => this.wrapHttpError(r));
  }

  getBots(): Promise<Map<string, string> | ErrorResponse> {
    this.setToken()
    return this.http.get(`${this.apiEndpoint}/bots`, {
      headers: this.headers
    }).toPromise().then(r => r as Map<string, string>)
      .catch(r => this.wrapHttpError(r));
  }

  generateTelegram(): Promise<Object> {
    this.setToken();

    return this.http.get(`${this.apiEndpoint}/bots/telegram/generator`, {
      headers: this.headers
    })
      .toPromise();
  }

  updateFP(network:string, fpuid:string, fp: Object): Promise<Object> {
    this.setToken();

    const url = `${this.apiEndpoint}/networks/`+network+`/family-profiles/`+fpuid

    return this.http.post(url, fp, {
      headers: this.headers
    })
      .toPromise();
  }
}
