import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileService } from '../../../../services/profile.service';
import { ApiMessage } from '../../../../models/api-message.model';
import { ErrorResponse } from '../../../../models/error-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { NetworkModel } from '../../../../models/network.model';
import { DeviceModel } from '../../../../models/device.model';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { TranslateService } from '@ngx-translate/core';

export interface IContext {
  data: string;
}


@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.sass']
})
export class LocationsComponent implements OnInit {
  isLoading = false;
  networks: NetworkModel[] = [];
  deviceAddForm: DeviceModel = new (DeviceModel);
  networkAddForm: NetworkModel = new (NetworkModel);

  interval: any;

  @ViewChild('modalAddDevice')
  public modalTemplate: ModalTemplate<IContext, string, string>;

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    public modalService: SuiModalService,
    public translate: TranslateService
  ) { }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  public openModalAddDevice() {
    const config = new TemplateModalConfig<IContext, string, string>(this.modalTemplate);

    config.closeResult = 'closed!';

    this.modalService
      .open(config)
      .onApprove(result => {
        this.addDevice();
        this.cleanDeviceAddForm();
      })
      .onDeny(result => {
        this.cleanDeviceAddForm();
      });
  }

  cleanDeviceAddForm() {
    this.deviceAddForm = new (DeviceModel);
  }

  cleanNetworkAddForm() {
    this.networkAddForm = new (NetworkModel);
  }

  addNetwork() {
    this.profileService.addNetwork(this.networkAddForm)
      .then((r: ApiMessage) => {
        this.profileService.getNetworks().then((rc: NetworkModel[]) => {
          if (rc instanceof ErrorResponse) {
            const re = rc as ErrorResponse;
            if (re.code === 401 || re.code === 403) {
              this.logout();
            }
          }
          this.networks = rc;
        });
      }
      ).catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        } else if (re.status >= 400) {
          alert('Error: ' + re.error.error);
        }
      });
  }


  addDevice() {
    this.profileService.addDevice(this.deviceAddForm)
      .then((r: ApiMessage) => {
        this.profileService.getNetworks().then((rc: NetworkModel[]) => {
          if (rc instanceof ErrorResponse) {
            const re = rc as ErrorResponse;
            if (re.code === 401 || re.code === 403) {
              this.logout();
            }
          }
          this.networks = rc;
        });
      }
      ).catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        } else if (re.status >= 400) {
          alert('Error: ' + re.error.error);
        }
      });
  }

  

  ngOnInit() {
    this.isLoading = true;
    this.refreshData();
    // this.interval = setInterval(() => {
    //   this.refreshData();
    // }, 15000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  refreshData() {
    Promise.all([
      this.profileService.getNetworks()
    ])
      .then((values: Object[]) => {
        if (this.responseIsNotAuth(values[0])) {
          return;
        }
        this.networks = values[0] as NetworkModel[];
        this.isLoading = false;
      })
      .catch((re: HttpErrorResponse) => {
        if (this.responseIsNotAuth(re)) {
          return;
        }
        if (re.status >= 400) {
          alert('Error: ' + re.error.error);
        }
      });
  }

  responseIsNotAuth(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code === 403 || error.code === 401) {
        this.logout();
      }
      return true;
    }
    return false;
  }

  buildKeendnsAddress(addr) {
    if (addr.endsWith('.link') || addr.endsWith('.name') || addr.endsWith('.pro')){
      return 'https://'+addr
    }
    return 'http://'+addr
  }
}
