import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from '../../../../services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from '../../../../models/error-response.model';

export interface IContext {
  data: string;
}

@Component({
  selector: 'app-familyProfiles',
  templateUrl: './familyProfiles.component.html',
  styleUrls: ['./familyProfiles.component.sass']
})
export class FamilyProfilesComponent implements OnInit {
  isLoading = false;
  currentUID = '';
  network = {}
  fpList = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private authService: AuthService,
    public modalService: SuiModalService,
    public translate: TranslateService
  ) {
    this.currentUID = this.route.snapshot.paramMap.get('networkuid');
  }


  ngOnInit() {
   this.updateFPList()
  }

  responseIsError(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code >= 400) {
        this.router.navigate(['/networks'])
      }
    }
  }

  updateFPList(){
    this.isLoading = true;

    Promise.all([
      this.profileService.getFamilyProfiles(this.currentUID)
    ])
      .then((values: Object[]) => {
        this.responseIsError(values[0])
        let resp = values[0] as Object;
        this.fpList = resp['fp']
        this.network = resp['network']
        this.isLoading = false;
      }).catch((re: HttpErrorResponse) => {
        this.router.navigate(['/networks']);
      });
  }

  setAccess(fpuid, status){
    this.isLoading=true

    this.profileService.updateFP(this.network['uid'], fpuid, {
      'access': status
    })
      .then(() => {
       this.updateFPList()
      }).finally(()=>{
        this.isLoading=false
      })
  }

 }