import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CDTrafficItemModel, CDTrafficModel } from '../../models/traffic.model';
import { Chart } from 'chart.js';
import { DataService } from '../../services/data.service';
import { ErrorResponse } from '../../models/error-response.model';


const _10m = 1000 * 60 * 10
const _1h = 1000 * 60 * 60
const _12h = _1h * 12
const _24h = _1h * 24
const _7d = _24h * 7
const _1m = _24h * 30
const _1y = 365 * _24h

@Component({
  selector: 'pie-chart',
  template: `
  <div class="chart-container">
    <canvas id="chartTraffic">
    </canvas>
  </div>

  <div [innerHTML]="legendvar" class="chart-legend"></div>
`,
  styleUrls: ['./pie-chart.component.sass'],
  styles: [
    ':host ::ng-deep li{list-style: none; color: rgba(0,0,0,.8)!important}',
    ':host ::ng-deep span.yaaahooooo {width: 10px; height: 10px; display: inline-block;margin-right:10px}',
    ':host ::ng-deep span.color-4e94ca{background-color: #4e94ca}',
    ':host ::ng-deep span.color-62a0d0 {background-color: #62a0d0}',
    ':host ::ng-deep span.color-74abd6 {background-color: #74abd6}',
    ':host ::ng-deep span.color-88b7dc {background-color: #88b7dc}',
    ':host ::ng-deep span.color-93bedf {background-color: #93bedf}',
    ':host ::ng-deep span.color-9cc3e2 {background-color: #9cc3e2}',
    ':host ::ng-deep span.color-b0d0e8 {background-color: #b0d0e8}',
    ':host ::ng-deep span.color-c3dbed {background-color: #c3dbed}',
    ':host ::ng-deep span.color-d7e7f3 {background-color: #d7e7f3}',
    ':host ::ng-deep span.color-ebf3f9 {background-color: #ebf3f9}'
  ]
})

export class PieChartComponent implements OnInit {
  @Input() network: string;
  @Input() cdList;
  @Input() limit;

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit() {
  }

  public legendvar = '';
  public chartTraffic;
  public chartLabels: string[] = []

  public setCurrentFilter(s: string) {
    this.chartLabels = []


    let v = this.dataService.getCDTopV2(this.network, s)
    if (v instanceof ErrorResponse) {
      const error = v as ErrorResponse;
    }

    let optionTraffic = {
      responsive: true,
      legend: false,
      tooltips: {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function (tooltipItems, data) {
            return data.labels[tooltipItems.index];
          }
        }
      },
      legendCallback: function (chart) {
        var text = [];
        text.push('<ul>');
        for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
          text.push('<li>');
          text.push('<span class="yaaahooooo color-' + chart.data.datasets[0].backgroundColor[i].slice(1) + '" ></span>');

          if (chart.data.labels[i]) {
            text.push(chart.data.labels[i]);
          }
          text.push('</li>');
        }
        text.push('</ul>');
        return text.join("");
      }

    }

    v.then((v: CDTrafficItemModel[]) => {
      let data = []

      let l = 0;

      v.forEach((e: CDTrafficItemModel) => {
        const name = this.getName(e.mac)
        if (l > 9 || !name) {
          return;
        }
        l++

        data.push(e.rx)
        this.chartLabels.push(name + ': ' + bytesToSize(e.rx))
      })

      if (this.chartTraffic) {
        this.chartTraffic.destroy()
      }

      this.chartTraffic = new Chart("chartTraffic", {
        type: 'pie',
        data: {
          datasets: [{
            data: data,
            backgroundColor: [
              "#4e94ca",
              "#62a0d0",
              "#74abd6",
              "#88b7dc",
              "#93bedf",
              "#9cc3e2",
              "#b0d0e8",
              "#c3dbed",
              "#d7e7f3",
              "#ebf3f9"
            ],
          }],
          labels: this.chartLabels,
        },
        options: optionTraffic
      });
      this.legendvar = this.chartTraffic.generateLegend();
    }).catch((v) => {
      this.chartTraffic = new Chart("chartTraffic", {
        type: 'pie',
        data: {
          labels: this.chartLabels,
        },
        options: optionTraffic
      });
    })

  }

  getName(mac) {
    for (let i = 0; i < this.cdList.length; i++) {
      if (this.cdList[i].mac == mac) {
        return this.cdList[i].name;
      }
    }
    return null;
  }

}


function bytesToSize(bytes) {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 B'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

