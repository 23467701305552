/**
 * Created by belkin on 28/12/17.
 */
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AccountComponent} from './screens/account/account.component';
import { LocationsComponent } from './screens/locations/locations.component';
import { SecurityComponent } from './screens/security/security.component';
import { ConnectedDevicesComponent } from './screens/connectedDevices/connectedDevices.component';
import { DeviceComponent } from './screens/device/device.component';
import { EventsComponent } from './screens/events/events.component';
import { FamilyProfilesComponent } from './screens/familyProfiles/familyProfiles.component';
import { FamilyProfileComponent } from './screens/familyProfile/familyProfile.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/networks'
  },
  {
    path: 'profile',
    pathMatch: 'full',
    component: AccountComponent
  },
  {
    path: 'networks',
    pathMatch: 'full',
    component: LocationsComponent
  },
  {
    path: 'networks/:networkuid/cd',
    pathMatch: 'full',
    component: ConnectedDevicesComponent
  },
  {
    path: 'networks/:networkuid/cd/:mac/',
    pathMatch: 'full',
    redirectTo: '/networks/:networkuid/cd'
  },
  {
    path: 'security',
    pathMatch: 'full',
    component: SecurityComponent
  },
  {
    path: 'networks/:networkuid/devices/:cid',
    pathMatch: 'full',
    component: DeviceComponent
  },
  {
    path: 'networks/:networkuid/devices/:cid/segments',
    pathMatch: 'full',
    redirectTo: '/networks/:networkuid/devices/:cid'
  },
  {
    path: 'networks/:networkuid/events',
    pathMatch: 'full',
    component: EventsComponent
  },
  {
    path: 'networks/:networkuid/fp',
    pathMatch: 'full',
    component: FamilyProfilesComponent,
  },
  {
    path: 'networks/:networkuid/fp/:fpuid',
    pathMatch: 'full',
    component: FamilyProfileComponent,
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProfileRoutingModule {
}

