import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass'],
  host: {
    '(document:click)': 'onClick($event)'
  }
})
export class DropdownComponent implements OnInit {
  @Input() items: Map<string, string>;
  @Input() value: string;
  @Output() changeValue = new EventEmitter<string>();

  isOpen = false;

  constructor(private _eref: ElementRef) {
  }

  ngOnInit() {}

  toggle() {
    this.isOpen = !this.isOpen;
  }

  close() {
    this.isOpen = false;
  }

  select(item) {
    this.value = item;
    this.changeValue.emit( this.value);
    this.close();
  }

  onClick(e) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  getKeys(map) {
    return Array.from(map.keys());
  }
}
