import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { LoginResponse } from '../models/login-response';
import { JWT } from '../../../helpers/jwt';
import { environment } from '../../../../environments/environment';



@Component({
  selector: 'app-auth-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.sass']
})
export class ProcessComponent implements OnInit {

  constructor(private router: ActivatedRoute,private authService: AuthService, private r: Router){
  }

  redirectdomain = "keenetic.cloud"

  ngOnInit() {
    if (!environment.production) {
      this.redirectdomain = 'dev.keenetic.cloud'
    }
    this.authService.ssoAuth(ObjToString(this.router.snapshot.queryParams)+'redirect_uri=https://'+this.redirectdomain+'/auth/process&locale='+getLastLocale()).then((resp: LoginResponse)=>{
      localStorage.setItem('token', resp.token);
      localStorage.setItem('cid', JWT.parse(resp.token).cid);
      this.authService.setToken(resp.token);
      setTimeout(() => {
        // this.r.navigate(['/']);
        window.location.href = "/"
      }, 1500);
    }).catch(()=>{
      this.r.navigate(["/auth"])
    })
  }


}

function ObjToString(o){
    let s = ""
    for (let key of Object.keys(o)){
      s+=key+"="+o[key]+"&"
    }

    return s
} 

function getLastLocale(){
  const l = localStorage.getItem("locale")
  if (l) {
    return l
  }

  return 'en'
}