import { Component, OnInit } from '@angular/core';
import { ClientModel } from '../../../../models/client.model';
import { ProfileService } from '../../../../services/profile.service';
import { ApiMessage } from '../../../../models/api-message.model';
import { ErrorResponse } from '../../../../models/error-response.model';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModal } from '../../../../components/modal-confirm/modal-confirm.component';
import { SuiModalService } from 'ng2-semantic-ui';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.sass']
})
export class SecurityComponent implements OnInit {
  clients: ClientModel[] = [];
  isLoading = false;
  interval: any;

  openDeleteClient(cid: String) {

    let str1: string;
    let str2: string;

    this.translate.get('profile.remove_session').subscribe((res: string) => {
      str1 = res;
    });

    this.translate.get('profile.confirm_remove_session').subscribe((res: string) => {
      str2 = res;
    });

    this.modalService.open(new ConfirmModal(str1, str2, 'tiny'))
        .onApprove(result => {
          this.deleteClient(cid);
        })
        .onDeny(result => {});
  }

  deleteClient(cid: String) {
    this.profileService.deleteClient(cid)
      .then((r: ApiMessage) => {
        this.profileService.getClients().then((rc: ClientModel[]) => {
          if (this.responseIsNotAuth(rc)) {
            return;
          }
          this.clients = rc;
        });
      }
      ).catch((re: HttpErrorResponse) => {
        if (re.status === 401 || re.status === 403) {
          this.logout();
        }
      });
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth']);
  }

  constructor(
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    public modalService: SuiModalService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.refreshData();
    this.interval = setInterval(() => {
      this.refreshData();
    }, 15000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  refreshData() {
    Promise.all([
      this.profileService.getClients()
    ])
      .then((values: Object[]) => {
        if (this.responseIsNotAuth(values[0])) {
          return;
        }
        this.clients = values[0] as ClientModel[];
        this.isLoading = false;
      });
  }

  tsToDateTime(unixtimestamp){
    // Months array
    const months_arr = ['Jan','Feb', 'Mar', 'Apr','May', 'Jun', 'Jul','Aug','Sep','Oct','Nov','Dec'];
    const date = new Date(unixtimestamp * 1000);
    const year = date.getFullYear();
    const month = months_arr[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const seconds = '0' + date.getSeconds();
    const convdataTime = month + '-' + day + '-' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return convdataTime;
  }

  responseIsNotAuth(o) {
    if (o instanceof ErrorResponse) {
      const error = o as ErrorResponse;
      if (error.code === 403 || error.code === 401) {
        this.logout();
      }
      return true;
    }
    return false;
  }
}
